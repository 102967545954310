.photo {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.image {
  position: relative;
  width: rem(300px);
  height: rem(300px);
  @media (min-width: $mantine-breakpoint-xs) {
    width: rem(350px);
    height: rem(350px);
  }
  @media (min-width: $mantine-breakpoint-sm) {
    width: rem(400px);
    height:rem(400px);
  }
  @media (min-width: $mantine-breakpoint-md) {
    width: rem(450px);
    height: rem(450px);
  }
  @media (min-width: $mantine-breakpoint-lg) {
    width: rem(480px);
    height: rem(480px);
  }
}
