.root {
  border: none;
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
}

.inner {
  width: 100%;
  height: 100%;
  display: flex;
  pointer-events: all;
};
