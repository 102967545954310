.facebook {
  background-color: #4267B2;
  color: #fff;
  &:hover {
    background-color: darken(#4267B2, 0.1);
  }
}

.leftSection {
  position: absolute;
  left: rem(16px);
}
