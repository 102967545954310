.aside {
  justify-content: center;
  background-color: var(--mantine-color-blue-1);
  @media (max-width: $mantine-breakpoint-md) {
    display: none;
  }
  @media (min-width: $mantine-breakpoint-md) and (max-width: $mantine-breakpoint-lg) {
    width: rem(350px);
  }
  @media (min-width: $mantine-breakpoint-lg) {
    width: rem(460px);
  }
}
