.root {
  width: 100%;
  transition: box-shadow 150ms ease, transform 100ms ease;

  @media (min-width: $mantine-breakpoint-sm) {
    @mixin hover {
      box-shadow: var(--mantine-shadow-md) !important;
    };
  };
}

.label {
  width: 100%;
}
