.root {
  --battery-height: rem(80px);
  --battery-tip-width: rem(16px);
  --battery-spacing: rem(16px);
  --battery-thumb-size: rem(40px);
  height: var(--battery-height);
}
.track {
  width: calc(100% - var(--battery-spacing));
  height: var(--battery-height);
  &::before {
    right: 0;
    border-radius: var(--battery-spacing);
  }
  &::after {
    content: "";
    position: absolute;
    background-color: var(--mantine-color-gray-4);
    right: rem(-16px);
    bottom: 25%;
    top: 25%;
    width: var(--battery-tip-width);
    border-top-right-radius: var(--battery-spacing);
    border-bottom-right-radius: var(--battery-spacing);
  }
}
.trackContainer {
  height: var(--battery-height);
}
.bar {
  border-radius: var(--battery-spacing);
  max-width: calc(100% + 1.25rem);
}
.thumb {
  width: var(--battery-thumb-size);
  height: var(--battery-thumb-size);
  cursor: grab;
}
